body {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: rgb(33, 37, 41);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
span,
li {
  color: rgba(255, 255, 255, 0.7) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: rgb(162, 175, 194);
}

a:hover {
  color: rgb(202, 220, 247);
}

@media (max-width: 991px) {
  .navbar-expand-lg {
    display: none;
  }
}

@media (min-width: 992px) {
  #navburger {
    display: none;
  }
}

.centered {
  margin: auto !important;
}

.job {
  background-color: transparent;
  border: none;
}

.navbar-brand {
  margin: auto;
  display: block;
}
.navbar-brand,
.nav-link {
  color: rgba(255, 255, 255, .55) !important;
}

.navbar-brand.active,
.nav-link.active {
  color: rgba(255, 255, 255, 1) !important;
}

.navbar-brand:hover,
.nav-link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.nav-link,
.nav-link:hover,
.link,
.link:hover,
.navbar-brand,
.navbar-brand:hover {
  transition: color 100ms linear;
}

.subheading {
  font-weight: 100;
  text-transform: uppercase;
}

.img-fit {
  float: left;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.gradient {
  background: linear-gradient(155deg, #574851 0%, #95A8B1 100%);
}

.link {
  color: rgba(255, 255, 255, .95);
  border: none !important;
  border-bottom: 1px solid #e6e9ed2a !important;
  background: none !important;
}

.link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.button-link {
  padding-left: 0;
  padding-right: 0;
  margin-left: 6px;
  margin-right: 6px;
}

/*
.link:after {
  content: " »";
}

.link {
  padding: 0 4px;
  border-radius: 4px;
  text-decoration: none;
  color: rgba(255, 255, 255, .95);
  transition: background-color 100ms linear;
}

.link:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.10);
}
*/

.btn-dark {
  background: #373b3eab;
}

.btn-outline-secondary {
  text-decoration: none;
  color: rgba(255, 255, 255, .55);
  border-color: rgba(255, 255, 255, .55);
}

.btn-outline-secondary:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: #212529;
}